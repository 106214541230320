import { showSnackbar } from '@/src/shared/lib/toast'

export class CopyButton extends HTMLElement {
  connectedCallback(): void {
    this.addEventListener('click', this.onClick)
  }

  disconnectedCallback(): void {
    this.removeEventListener('click', this.onClick)
  }

  get text(): string {
    return this.getAttribute('text') || ''
  }

  onClick = (): void => {
    navigator.clipboard.writeText(this.text)
    showSnackbar('Copied!')
  }
}

declare global {
  interface Window {
    CopyButton: typeof CopyButton
  }
}

if (!window.customElements.get('copy-button')) {
  window.CopyButton = CopyButton
  window.customElements.define('copy-button', CopyButton)
}
