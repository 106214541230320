import { scriptJsonData } from '@/src/lib/json'
import { showError, showSuccess } from '@/src/shared/lib/toast'

const SCRIPT_JSON_FLASH_ID = 'json-flash'

export type FlashData = {
  notice?: string
  alert?: string
}

const isBackForwardNavigation = () => {
  // NOTE: PerformanceEntry.typeが未定義なので
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return performance.getEntriesByType('navigation').some((entry) => (entry as any).type === 'back_forward')
}

const displayFlashMessage = (): void => {
  if (isBackForwardNavigation()) return

  const flashData = scriptJsonData<FlashData>(SCRIPT_JSON_FLASH_ID)
  if (!flashData) return

  if (flashData.notice) showSuccess(flashData.notice)
  if (flashData.alert) showError(flashData.alert)
}

export const start = (): void => {
  document.addEventListener(
    'DOMContentLoaded',
    () => {
      displayFlashMessage()
    },
    { once: true },
  )
}
