import { DirectUpload } from '@rails/activestorage'

const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'

type SignedId = string

export const directUpload = (file: File): Promise<SignedId> => {
  const upload = new DirectUpload(file, DIRECT_UPLOAD_URL)

  return new Promise((resolve, reject) => {
    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        resolve(blob.signed_id)
      }
    })
  })
}
