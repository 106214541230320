import type { SetRequired } from 'type-fest'
import type { ImageCompressionOptions, Destroy } from '@/src/lib/imageCompressionField'

const DEFAULT_OPTIONS: SetRequired<ImageCompressionOptions, 'maxWidthOrHeight' | 'maxSizeMB' | 'initialQuality'> = {
  maxWidthOrHeight: 2048,
  maxSizeMB: 2,
  initialQuality: 0.9,
}

type State = {
  destroy: Destroy
}
const states = new WeakMap<ImageCompression, State>()

export class ImageCompression extends HTMLElement {
  async connectedCallback() {
    const { imageCompressionField } = await import('@/src/lib/imageCompressionField')
    const destroy = imageCompressionField(this, {
      initialQuality: this.initialQuality,
      maxSizeMB: this.maxSizeMB,
      maxWidthOrHeight: this.maxWidthOrHeight,
    })
    states.set(this, { destroy })
  }

  disconnectedCallback(): void {
    states.get(this)?.destroy()
  }

  get initialQuality(): number {
    const value = this.getAttribute('initial-quality')
    return value ? Number.parseFloat(value) : DEFAULT_OPTIONS.initialQuality
  }

  get maxSizeMB(): number {
    const value = this.getAttribute('max-size-mb')
    return value ? Number.parseInt(value, 10) : DEFAULT_OPTIONS.maxSizeMB
  }

  get maxWidthOrHeight(): number {
    const value = this.getAttribute('max-width-or-height')
    return value ? Number.parseInt(value, 10) : DEFAULT_OPTIONS.maxWidthOrHeight
  }
}

declare global {
  interface Window {
    ImageCompression: typeof ImageCompression
  }
}

if (!window.customElements.get('image-compression')) {
  window.ImageCompression = ImageCompression
  window.customElements.define('image-compression', ImageCompression)
}
