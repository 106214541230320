import type BrowserImageCompression from 'browser-image-compression'

export type ImageCompressionOptions = Parameters<typeof BrowserImageCompression>[1]

const DEFAULT_OPTIONS: ImageCompressionOptions = {
  maxWidthOrHeight: 2048,
}

/**
 * @deprecated
 */
export const imageCompression = async (file: File, options: ImageCompressionOptions = {}): Promise<File> => {
  // eslint-disable-next-line unicorn/no-await-expression-member
  const browserImageCompression = (await import('browser-image-compression')).default

  return browserImageCompression(file, {
    ...DEFAULT_OPTIONS,
    ...options,
  })
}

export const isSupportedImageCompression = (file: File): boolean => {
  return ['image/jpeg', 'image/png'].includes(file.type)
}
