import type { TurboBeforeFrameRenderEvent, TurboFrameRenderEvent } from '@hotwired/turbo'
import { Turbo } from '@hotwired/turbo-rails'
import morphdom from 'morphdom'
import type { FlashData } from '@/src/shared/lib/displayFlashMessage'
import { showSnackbar, showError } from '@/src/shared/lib/toast'

export const startTurbo = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(Turbo as any).session.drive = false

  document.addEventListener('turbo:render', () => {
    // NOTE: data-turbo-action: advanceのケースでフォームの状態が連動して更新されるように
    for (const form of document.querySelectorAll<HTMLFormElement>('form[data-turbo-reset]')) {
      form.reset()
    }
  })

  document.addEventListener('turbo:frame-render', (event: Event) => {
    const json = (event as TurboFrameRenderEvent).detail.fetchResponse.header('X-Flash-Messages')
    if (!json) return

    const flashData: FlashData = JSON.parse(json)

    if (flashData.notice) showSnackbar(flashData.notice)
    if (flashData.alert) showError(flashData.alert)
  })

  document.addEventListener('turbo:before-frame-render', (event) => {
    const { detail } = event as TurboBeforeFrameRenderEvent
    const { newFrame } = detail

    if (!newFrame.dataset.turboMorph) return

    // NOTE: 全体の動作を変えるのは怖いので data-turbo-morph 属性が指定されている場合のみ morphdom で差分描画する
    detail.render = (currentElement: Element, newElement: Element) => {
      morphdom(currentElement, newElement, {
        childrenOnly: true,
        getNodeKey(node) {
          if (!(node instanceof HTMLElement)) return

          return node.dataset.morphKey ?? node.id
        },
        onBeforeNodeDiscarded(el) {
          if (!(el instanceof HTMLElement)) return true

          return !el.dataset.morphIgnore
        },
        onBeforeElUpdated(fromEl, _toEl) {
          return !fromEl.dataset.morphIgnore
        },
      })
    }
  })
}
