import YubinBango from 'yubinbango.js'
import { showError } from '@/src/shared/lib/toast'

const WRAPPER_SELECTOR = '.js-yubinbango'
const POSTAL_CODE_SELECTOR = '.js-postal-code'
const ADDRESS_SELECTOR = '.js-address'
const BUTTON_SELECTOR = '.js-postal-code-button'

const postalCode2Address = async (postalCode: string): Promise<string | undefined> => {
  try {
    const address = await YubinBango.getAddress(postalCode)
    const { prefecture, locality, street, extended } = address
    return [prefecture, locality, street, extended].join('')
  } catch {
    return undefined
  }
}

const inputAddressFromPostal = async (element: HTMLInputElement) => {
  const postalCode = element.value

  if (!/^\d{3}-?\d{4}$/.test(postalCode)) {
    showError('郵便番号を正しく入力してください。')
    return
  }

  const address: string | undefined = await postalCode2Address(postalCode)
  if (!address) {
    showError('該当する郵便番号が見つかりませんでした。')
    return
  }

  const addressElement: HTMLInputElement | undefined | null = element
    .closest(WRAPPER_SELECTOR)
    ?.querySelector(ADDRESS_SELECTOR)

  if (!addressElement) {
    throw new Error('Not found addressElement')
  }

  addressElement.value = address
}

export const autoInputAddress = (): void => {
  document.addEventListener('click', (event) => {
    const button = event.target instanceof HTMLElement && event.target.closest<HTMLElement>(BUTTON_SELECTOR)
    if (!button) return

    const element = button.closest(WRAPPER_SELECTOR)?.querySelector<HTMLInputElement>(POSTAL_CODE_SELECTOR)

    if (element) {
      inputAddressFromPostal(element)
    }
  })
}
