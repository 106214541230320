import { openToast } from '@sonicgarden/bs-toast-element'

export function showSuccess(message: string): void {
  openToast(message, { bgColor: 'success', delay: 5000 })
}

export function showError(message: string | HTMLElement): void {
  openToast(message, { bgColor: 'danger', delay: 20_000 })
}

export function showErrors(errors: readonly string[]): void {
  const ul = document.createElement('ul')
  ul.classList.add('mb-0', 'ps-3')
  for (const error of errors) {
    const li = document.createElement('li')
    li.textContent = error
    ul.append(li)
  }

  showError(ul)
}

export function showSnackbar(message: string): void {
  openToast(message, { color: 'white', bgColor: 'dark', delay: 1500, position: 'bottom-center' })
}
