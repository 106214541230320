import type { AnimationController } from '@formkit/auto-animate'
import autoAnimate from '@formkit/auto-animate'

type State = {
  controller: AnimationController
}
const states = new WeakMap<AutoAnimate, State>()

export class AutoAnimate extends HTMLElement {
  connectedCallback(): void {
    requestAnimationFrame(() => this.init())
  }

  disconnectedCallback(): void {
    states.get(this)?.controller.disable()
  }

  init(): void {
    const element = this.for ? this.querySelector<HTMLElement>(`#${this.for}`) : this
    if (!element) return
    const controller = autoAnimate(element)
    states.set(this, { controller })
  }

  get for(): string | undefined {
    return this.getAttribute('for') ?? undefined
  }

  set for(value) {
    if (value) {
      this.setAttribute('for', value)
    } else {
      this.removeAttribute('for')
    }
  }
}

declare global {
  interface Window {
    AutoAnimate: typeof AutoAnimate
  }
}

if (!window.customElements.get('auto-animate')) {
  window.AutoAnimate = AutoAnimate
  window.customElements.define('auto-animate', AutoAnimate)
}
